<template>
        <div class="header">
                <topbar />
                <div class="main text-center">
                        <el-row :gutter="0" style="width:100%;">
                                <el-col class="logo-area"  :md="6" :xs="24" :sm="8">
                                        <a href="/">
                                                <img style="max-width:200px;" src="@/assets/logo.png" alt="">
                                        </a>
                                </el-col>
                                <el-col :md="12" :xs="24" :sm="14">
                                        <search @search="onsearch"/>
                                </el-col>
                                <el-col :md="6" :xs="24" :sm="6" class="text-right hidden-sm-and-down">
                                        
                                </el-col>
                        </el-row>
                </div>
        </div>
</template>
<script>
import topbar from './topbar.vue'
import search from './search.vue'
export default {
        components:{
                topbar,
                search
        },
        methods:{
                onsearch(val) {
                        this.$emit('search',val)
                }
        }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 767px){
        .logo-area{
                text-align: center;
        }
}
@media screen and (min-width: 767px){
        .logo-area{
                text-align: left;
        }
}
        .header{
                background-color: @bg-color;
                >.main{
                        width:100%;
                        max-width: @max-width;
                        margin:0 auto;
                        box-sizing: border-box;
                        padding: @pd-lg @pd-md;
                        display: flex;
                        align-items: center;
                        
                }
        }
</style>