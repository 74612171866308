import axios from 'axios'
import Vue from 'vue'
const instance = axios.create({
        baseURL: '',
        timeout: 10000,
})
instance.interceptors.request.use(config => config, err => Promise.reject(err))
instance.interceptors.response.use(response => response, err => Promise.reject(err))

export const http = async (method, api, data = {}, headers = {}) => {
        method = method.toLowerCase()
        try {
                var response;
                let request_body = {
                        ...data
                }
                let request_headers = {
                        timestamp: (new Date()).getTime() / 1000,
                        token: localStorage.getItem('token') || '',
                        ...headers
                }
                switch (method) {
                        case 'get':
                                response = await instance.get(api, { headers: request_headers, params: request_body })
                                break;
                        case 'delete':
                                response = await instance.delete(api, { headers: request_headers, params: request_body })
                                break;
                        case 'post':
                                response = await instance.post(api, request_body, { headers: request_headers })
                                break;
                        case 'put':
                                response = await instance.put(api, request_body, { headers: request_headers })
                                break;
                        default:
                                throw new Error('Invalid method：' + method)
                }
                if(response.status != 200) throw new Error('[' + response.status + ']:' + response.statusText)
                let res = response.data;
                if(res.code == 1) {
                        throw new Error(res.msg)
                } else if(res.code == 2) {
                        //这里要进行跳转授权
                        localStorage.removeItem('token')
                        location.href = '/login.html'
                        return false
                } else if(res.code == 0) {
                        //code = 0正常返回
                        return res.data
                } else {
                        throw new Error('Unkown code:[' + res.code + ']' + res.msg)
                }
        } catch (err) {
                Vue.prototype.$message.error(err.message)
                return false;
        }
}
export const download = (method,url, data) => {
        // tips: 这里直接返回的是response整体!
        return instance.request({
                url,
                method,
                data,
                headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json; charset=utf-8',
                        'withCredentials': true,
                        'timestamp': (new Date()).getTime() / 1000,
                        'token': localStorage.getItem('token') || ''
                },
                responseType:'arraybuffer',
        }).catch(err => console.log(err))
}
export default {
        http,
        download
}